<template>
  <transition-group
    name="fade-intro"
    mode="out-in"
    tag="div"
    class="intro__overlay"
  >
    <!-- Intro -->
    <div class="intro" v-if="currentStep === 1 && tour" key="intro">
      <h1>Preview tour</h1>
      <p>
        Full experience <br />
        coming soon!
      </p>
      <Button @click.native.prevent="handleClick(1)"> Get started </Button>
    </div>

    <!-- Tutorial -->
    <div class="tutorial" v-if="currentStep === 2" key="tutorial">
      <img src="/assets/img/tutorial.svg" alt="Tutorial drag icon" />
      <p v-if="isTouchDevice()">{{ $t.tutorialTextMobile }}</p>
      <p v-else>{{ $t.tutorialText }}</p>
      <Button @click.native.prevent="handleClick(1)">
        {{ $t.tutorialButton }}
      </Button>
    </div>
  </transition-group>
</template>

<script>
import Button from "@preview/Button.vue"
import { isTouchDevice } from "@/helpers"

export default {
  name: "IntroScreen",
  components: {
    Button,
  },
  data() {
    return {
      currentStep: 1,
    }
  },
  methods: {
    isTouchDevice: isTouchDevice,
    handleClick(step) {
      switch (step) {
        case 1:
          if (!window.embedded && localStorage.getItem("tutorialShown")) {
            this.$emit("close")
          } else {
            this.currentStep = 2
            if (!window.embedded) {
              localStorage.setItem("tutorialShown", true)
            }
          }
          break
        case 2:
          this.$emit("close")
          break
      }
    },
  },
  computed: {
    tour() {
      return this.$store.state.currentTour
    },
  },
}
</script>

<style lang="scss" scoped>
.intro__overlay {
  position: absolute;
  z-index: 10000;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  background-color: transparent;
  transition: opacity $basic-transition-200;
  background-color: rgba($color-black, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  .fade-intro-enter-active {
    transition: opacity 0.2s linear 0.5s;
    position: absolute;
  }

  .fade-intro-leave-active {
    transition: opacity 0.2s linear;
    position: absolute;
  }

  .fade-intro-enter,
  .fade-intro-leave-to {
    opacity: 0;
  }
}

.intro {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 5.2rem 2.8rem 2.8rem 2.8rem;
  background: var(--grass-color);
  border-radius: 9999px 9999px 0 0;
  min-width: 24rem;

  @include small-up {
    padding: rs(52) rs(28) rs(28) rs(28);
    min-width: rs(240);
  }

  h1 {
    font-size: 1.4rem;
    line-height: 1.565rem;
    font-weight: 500;
    opacity: 0.5;
    color: var(--pine-color);
    margin-bottom: 1.6rem;

    @include small-up {
      font-size: rs(14);
      line-height: rs(15.65);
      margin-bottom: rs(16);
    }
  }

  p {
    color: var(--pine-color);
    font-size: 1.8rem;
    margin-bottom: 2.4rem;

    @include small-up {
      font-size: rs(18);
      margin-bottom: rs(24);
    }
  }

  a {
    min-width: 19rem;
    text-align: center;

    @include small-up {
      min-width: rs(190);
    }
  }
}

.tutorial {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  grid-gap: 3.6rem;
  max-width: 80%;
  opacity: 1;

  p {
    font-size: 1.4rem;
    line-height: 1.565rem;
    color: $color-white;
  }

  @include small-up {
    max-width: 50%;
    grid-gap: rs(36);

    p {
      font-size: rs(17);
      line-height: rs(18);
    }
  }

  h1 {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 32px;
    line-height: 32px;
    color: $color-white;

    @include small-up {
      font-size: rs(64);
      line-height: rs(68);
    }
  }
}
</style>
