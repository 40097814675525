<template>
  <Preview v-if="preview" />
  <VirtualTour v-else />
</template>

<script>
import VirtualTour from "@vtour/VirtualTour.vue"
import Preview from "@preview/Preview.vue"
import { getSubdomain } from "@/helpers"

export default {
  name: "Krpano",
  components: {
    VirtualTour,
    Preview,
  },
  computed: {
    preview() {
      const subdomain = getSubdomain(process.env.VUE_APP_BASE_URL)
      if (subdomain) {
        return subdomain.includes("preview")
      } else {
        return false
      }
    },
  },
}
</script>
