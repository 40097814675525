<template>
  <div
    :class="`side-menu__container ${open ? 'side-menu__container_open' : ''}`"
  >
    <!-- Header -->
    <div class="side-menu__inner" v-if="tour">
      <div class="side-menu__header">
        <h1>{{ tour.title }}</h1>
        <a
          class="side-menu__close"
          href="#!"
          @click.prevent="$emit('close')"
          tabindex="-1"
        >
          <img :src="`/assets/img/icons/icon-close.svg`"
        /></a>
      </div>

      <!-- Content -->
      <div class="side-menu__content">
        <CategoryList
          v-for="cat in categories"
          :category="cat"
          :key="cat.id"
          :tier="1"
          :open="
            $store.state.currentPano.categories &&
            $store.state.currentPano.categories.some((c) => c.id === cat.id)
          "
          @close="$emit('close')"
        />
      </div>

      <!-- Footer -->
      <div class="side-menu__footer">
        <LanguageSelect />
        <div class="credit" v-if="$t.uiPoweredBy">
          <a href="https://poppr.be/" rel="noopener noreferrer" target="_blank">
            {{ $t.uiPoweredBy }}
          </a>
        </div>
      </div>
    </div>
    <div class="side-menu__outer" @click="$emit('close')"></div>
  </div>
</template>

<script>
import CategoryList from "@vtour/CategoryList.vue";
import LanguageSelect from "@vtour/LanguageSelect.vue";

export default {
  name: "SideMenu",
  components: {
    CategoryList,
    LanguageSelect,
  },
  props: {
    open: Boolean,
    close: Function,
  },
  computed: {
    tour() {
      return this.$store.state.currentTour;
    },
    categories() {
      return this.$store.state.currentCategories
        ? this.$store.state.currentCategories.filter((c) => !c.parent)
        : null;
    },
    pano() {
      const activePano = this.tour.panos.find(
        (p) => p.slug == this.$store.state.currentPano.slug
      );
      if (activePano) {
        this.$store.state.currentPano = activePano;
      }
      return activePano;
    },
  },
};
</script>

<style lang="scss">
.side-menu {
  &__container {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.25s ease-out;
    visibility: hidden;

    &_open {
      .side-menu__inner {
        right: 0;
        box-shadow: 4px 0 32px 4px rgba(0, 0, 0, 0.2);
      }

      .side-menu__outer {
        pointer-events: all;
        opacity: 0.6;
      }
    }
  }

  &__inner {
    background-color: white;
    backdrop-filter: blur(5px);
    position: absolute;
    right: -100%;
    transition: right $basic-transition-500 0.1s;
    visibility: visible;
    z-index: 1003;
    pointer-events: all;
    box-shadow: none;
    max-height: 100%;
    overflow: hidden;
    scroll-behavior: smooth;
    margin: 0 auto;
    padding: 0 !important;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    @include small-up {
      width: fit-content;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 3rem 2rem 4rem !important;
    gap: 3.2rem;

    .side-menu__close {
      z-index: 1;
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      background: transparent;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all $bounce-transition-300;
      }

      &:hover img {
        opacity: 0.7;
        transform: rotate(90deg) scale(1.2);
      }
    }
  }

  &__content {
    padding-bottom: 8rem !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAC9SURBVHjarJLbasNADETPSGtj//9XFtqHQIJd2JX6YNwkDvSyyT6JHTRoLjpdzglQSqHWyrquAEzT9P13xM2MUgqlFHRL0FpjWRYA5nnG3e8IdnwcR9wdSejyuSZAa43M5PZJwt054pIws23eL/hp4YhHBGZGRKC3j/f8TfMRlwTwIoIeCa01zIzM7DMxM7cIpfsY/9oDd2cYhi3Knh6Y2ZWgR0JEPEr4bw8kbSb2xLi3sNb6AoKnJTxr4tcAshUe+EPfCsoAAAAASUVORK5CYII=);
  }

  &__outer {
    background-color: var(--primary-color);
    cursor: pointer;
    visibility: visible;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1002;
    transition: opacity $basic-transition-500 0.1s;
    pointer-events: none;
  }

  &__content {
    overflow: auto;
    width: 100%;
    height: 100%;
  }

  &__footer {
    background: white;
    box-shadow: 0px -5px 15px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    z-index: 2;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1rem !important;

    * {
      color: black;
    }

    .credit {
      margin-top: 1rem;

      a {
        text-decoration: none;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.4rem;
      }
    }
  }
}
</style>
