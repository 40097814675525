<template>
  <ul
    :class="`category category__tier-${tier} ${
      openCategory || currentActive ? 'category--open' : ''
    }`"
  >
    <div
      :class="`category__title ${open ? 'category__title--open' : ''}`"
      @click="handleCategoryClick"
    >
      <h3 :class="`title-${tier}`">{{ category.title }}</h3>
      <a class="category__open" href="#!" tabindex="-1" @click.prevent="">
        <img
          :src="`/assets/img/icons/icon-open--open.svg`"
          v-if="openCategory || currentActive"
        />
        <img :src="`/assets/img/icons/icon-open--close.svg`" v-else />
      </a>
    </div>
    <transition name="show-category">
      <div v-if="openCategory || currentActive" class="category__content">
        <li
          v-for="pano in category.panos"
          :key="pano.id"
          :class="`category__item title-${tier + 1} ${
            $store.state.currentPano.title.trim() === pano.title.trim()
              ? 'active'
              : ''
          }`"
          @click="handlePanoClick(pano)"
        >
          {{ pano.title }}
          <img
            v-if="pano.type.value === 'video'"
            class="nav__360-icon"
            src="/assets/img/icons/icon-360.svg"
          />
        </li>
        <CategoryList
          v-for="cat in category.children"
          :category="cat"
          :key="cat.id"
          :tier="tier + 1"
          :open="
            $store.state.currentPano.categories &&
            $store.state.currentPano.categories.some((c) => c.id === cat.id)
          "
          @close="$emit('close')"
        />
      </div>
    </transition>
  </ul>
</template>

<script>
export default {
  name: "CategoryList",
  props: ["category", "open", "tier"],
  data() {
    return {
      openCategory: this.open,
    };
  },
  computed: {
    currentActive() {
      if (this.$store.state.currentPano) {
        return (
          this.$store.state.currentPano.categories[0].id === this.category.id
        );
      } else return false;
    },
  },
  mounted() {
    if (this.tier === 1) {
      this.$root.$on("clicked-category", (id) => {
        if (this.category.id !== id) {
          this.openCategory = false;
        }
      });
    }
    this.$root.$on("clicked-navigate", (pano) => {
      this.openCategory = false;
    });
  },
  methods: {
    handleCategoryClick() {
      this.openCategory = !this.openCategory;
      if (this.tier === 1) {
        this.$root.$emit("clicked-category", this.category.id);
      }
    },
    handlePanoClick(pano) {
      this.$loadPano(pano);
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.category {
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-top: 2rem;
  transition: all 10s linear;

  &--open {
    .category__content--open {
      display: block;
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    gap: 4.2rem;
    align-items: center;
    padding: 2rem 3rem 2rem 4rem !important;
    transition: all 0.1s linear;

    h3 {
      transition: all 0.1s linear;
      font-weight: 500;
    }

    .category__open {
      z-index: 1;
      display: block;
      width: 2rem;
      height: 2rem;
      background: transparent;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &:hover,
    &--open {
      background: var(--primary-color);
      cursor: pointer;

      * {
        color: white;
      }

      img {
        filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(140deg)
          brightness(108%) contrast(101%);
      }
    }
  }

  .title {
    &-1 {
      font-size: 2.8rem;
      line-height: 2.8rem;
    }

    &-2 {
      font-size: 2rem;
      line-height: 2rem;
    }

    &-3 {
      font-size: 1.8rem;
      line-height: 1.8rem;
      margin-left: 2rem;
    }

    @for $i from 4 through 10 {
      &-#{$i} {
        font-size: 1.6rem;
        line-height: 1.6rem;
        margin-left: calc(1rem * #{$i});
      }
    }
  }

  &__content {
    margin-top: 1rem;
    position: relative;

    &.show-category-enter-active,
    &.show-category-leave-active {
      transition: all $basic-transition-200;
      transform: translateY(0rem);
    }

    &.show-category-enter,
    &.show-category-leave-to {
      opacity: 0;
      transform: translateY(-2rem);
    }
  }

  &__item {
    margin-top: 1rem;
    padding: 1rem 3rem 1rem 4rem !important;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      margin-left: 1.6rem;
      width: 7.2rem;
    }

    &:hover,
    &.active {
      color: var(--primary-color);
    }
  }
}
</style>
