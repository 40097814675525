<template>
  <a
    :href="link"
    class="button"
    :target="outbound ? '_blank' : ''"
    :rel="outbound ? 'noopener noreferrer' : ''"
  >
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: "Button",
  props: {
    link: { default: "#!" },
    outbound: { default: false },
  },
};
</script>

<style lang="scss" scoped>
.button {
  max-width: 25rem;
  background: linear-gradient(
    to right,
    var(--secondary-color-darker) 50%,
    var(--secondary-color) 50%
  );
  background-size: 200% 110%;
  background-position: right bottom;
  border-radius: 50rem;
  box-sizing: border-box;
  color: var(--primary-color);
  cursor: pointer;
  padding: 1.6rem 3.2rem;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  overflow: hidden;

  transition: all $basic-transition-300;

  &:hover {
    background-position: left bottom;
  }
}
</style>
