<template>
  <transition-group
    name="fade-intro"
    mode="out-in"
    tag="div"
    class="intro__overlay"
  >
    <!-- Intro -->
    <div class="intro" v-if="currentStep === 1 && tour" key="intro">
      <h1>{{ tour.title }}</h1>
      <p>
        {{
          $store.state.currentTour.description
            ? $store.state.currentTour.description
            : $t.introText
        }}
      </p>
      <Button @click.native.prevent="handleClick(1)">
        {{ $t.introButton }}
      </Button>
    </div>

    <!-- Tutorial -->
    <div class="tutorial" v-if="currentStep === 2" key="tutorial">
      <img src="/assets/img/tutorial.svg" alt="Tutorial drag icon" />
      <p v-if="isTouchDevice()">{{ $t.tutorialTextMobile }}</p>
      <p v-else>{{ $t.tutorialText }}</p>
      <Button @click.native.prevent="handleClick(2)">
        {{ $t.tutorialButton }}
      </Button>
    </div>
  </transition-group>
</template>

<script>
import Button from "@vtour/Button.vue"
import { isTouchDevice } from "@/helpers"

export default {
  name: "IntroScreen",
  components: {
    Button,
  },
  data() {
    return {
      currentStep: 1,
    }
  },
  methods: {
    isTouchDevice: isTouchDevice,
    handleClick(step) {
      switch (step) {
        case 1:
          if (!window.embedded && localStorage.getItem("tutorialShown")) {
            this.$emit("close")
          } else {
            this.currentStep = 2
            if (!window.embedded) {
              localStorage.setItem("tutorialShown", true)
            }
          }
          break
        case 2:
          this.$emit("close")
          break
      }
    },
  },
  computed: {
    tour() {
      return this.$store.state.currentTour
    },
  },
}
</script>

<style lang="scss" scoped>
.intro__overlay {
  position: absolute;
  z-index: 10000;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  background-color: transparent;
  transition: opacity $basic-transition-200;
  background-color: rgba($color-black, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  .fade-intro-enter-active {
    transition: opacity 0.2s linear 0.5s;
    position: absolute;
  }

  .fade-intro-leave-active {
    transition: opacity 0.2s linear;
    position: absolute;
  }

  .fade-intro-enter,
  .fade-intro-leave-to {
    opacity: 0;
  }
}

.intro,
.tutorial {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  grid-gap: 3.6rem;
  max-width: 80%;
  opacity: 1;

  @include small-up {
    max-width: 50%;
  }
}

* {
  color: $color-white;
}

h1 {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 32px;
  line-height: 32px;

  @include small-up {
    font-size: 64px;
    line-height: 64px;
  }
}
</style>
