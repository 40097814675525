<template>
  <div class="home">
    <h1>{{ $t.interfacePageTitle }}</h1>
    <ul>
      <li v-for="tour in tours" :key="tour.slug">
        <a
          :href="`${tour.url}${locale}`"
          rel="noreferrer noopener"
          target="_blank"
        >
          {{ tour.title }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import virtualTourApi from "@/api";

export default {
  name: "Home",
  data() {
    return {
      tours: [],
      locale: this.$locale,
    };
  },
  mounted() {
    virtualTourApi.getAllTours().then((res) => (this.tours = res.data));
  },
};
</script>
<style lang="scss">
.home {
}
</style>
