<template>
  <div class="shape-transition">
    <div class="icon" v-if="icon === 'drop'">
      <svg
        id="hi"
        width="550"
        height="664"
        viewBox="0 0 550 664"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M80.6572 583.028C-26.6686 475.703 -26.6686 301.669 80.6572 194.343L275 0L469.343 194.343C576.669 301.669 576.669 475.703 469.343 583.028C362.017 690.354 187.983 690.354 80.6572 583.028Z"
          fill="#DAFFD1"
        />

        <clipPath id="invert">
          <path
            d="M80.6572 583.028C-26.6686 475.703 -26.6686 301.669 80.6572 194.343L275 0L469.343 194.343C576.669 301.669 576.669 475.703 469.343 583.028C362.017 690.354 187.983 690.354 80.6572 583.028Z"
            fill="#DAFFD1"
          />
        </clipPath>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "TransitionElement",
  props: {
    icon: { default: "drop" },
  },
}
</script>

<style lang="scss">
.shape-transition {
  z-index: 1000000000;
  position: fixed;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  pointer-events: none;

  * {
    pointer-events: none;
  }

  .icon {
    opacity: 0;
    object-fit: contain;
    transform-origin: center top;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  #invert {
    transform-origin: center top;
    transform: translate(calc(50vw - 50%), calc(0%)) scale(1);
  }
}
</style>
