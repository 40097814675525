<template>
  <ul class="breadcrumb">
    <li>
      <a href="#" @click.prevent="$loadPano(tour.panos[0])">
        {{ $t.uiBreadcrumbHome }}
      </a>
    </li>
    <li v-for="cat in $store.state.currentPano.categories" :key="cat.id">
      <a
        href="#"
        @click.prevent="handleCategoryClick(cat)"
        :class="`${
          !flatCategories.find((c) => c.id === cat.id).panos.length > 0
            ? 'no-panos'
            : ''
        }`"
      >
        {{ cat.title }}
      </a>
    </li>
    <li>
      <a href="#" @click.prevent="" class="no-panos current">
        {{ $store.state.currentPano.title }}
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "BreadCrumb",
  data() {
    return {
      title: null,
    };
  },
  computed: {
    tour() {
      return this.$store.state.currentTour;
    },
    flatCategories() {
      return this.$store.state.currentTour.flatCategories;
    },
  },
  methods: {
    handleCategoryClick(category) {
      this.$loadPano(
        this.flatCategories.find((c) => c.id === category.id).panos[0]
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb {
  display: none;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin: $ui-padding 0 0 0;

  li a {
    color: var(--secondary-color);
    transition: opacity 0.07s linear;

    &.no-panos {
      pointer-events: none;
      text-decoration: none;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  li ~ li:before {
    content: ">";
    color: var(--secondary-color);
    margin: 0 1rem;
    display: flex;
    align-items: center;
  }

  @include small-up {
    display: flex;
  }
}
</style>
