import anime from "animejs"

export class Transitions {
  static dropTransition(callback = () => {}) {
    const shape = document.querySelector(".shape-transition .icon")
    const shapeInvert = shape.querySelector("#invert")
    const page = document.querySelector(".krpano__wrapper")

    return (
      anime
        .timeline({
          easing: "linear",
        })
        // First drop
        .add({
          targets: shape,
          rotate: ["20deg", "0deg"],
          scale: [0, 1],
          duration: 250,
          begin: () => {
            shape.style.opacity = 1
            shape.style.transform = "scale(1) translate(0) rotate(0)"
          },
        })
        .add({
          targets: shape,
          rotate: ["0deg", "-5deg"],
          scale: [1, 1.25],
          duration: 75,
        })
        .add({
          targets: shape,
          rotate: ["-5deg", "0deg"],
          scale: [1.25, 1.5],
          duration: 125,
        })
        .add({
          targets: shape,
          translateY: "-50%",
          scale: [1.5, 10],
          duration: 500,
          begin: () => {
            callback()
          },
          complete: () => {
            shape.style.opacity = 0
            shape.style.transform = "scale(1) translate(0) rotate(0)"
            anime.remove(shape)
            page.classList.add("clipped")
          },
        })
        // Second drop
        .add(
          {
            targets: shapeInvert,
            translateY: ["5vh", "5vh"],
            translateX: ["calc(50vw - 50%)", "calc(50vw - 50%)"],
            rotate: ["20deg", "0deg"],
            scale: [0, window.innerWidth <= 799 ? 1 : 2],
            duration: 250,
          },
          window.showingSplash ? 1500 : 975
        )
        .add({
          targets: shapeInvert,
          translateY: ["5vh", `calc(-50vh - ${window.innerWidth <= 799 ? 50 : 250}%)`],
          translateX: ["calc(50vw - 50%)", "calc(50vw - 50%)"],
          rotate: ["0deg", "-5deg"],
          scale: [window.innerWidth <= 799 ? 1 : 2, window.innerWidth <= 799 ? 10 : 20],
          duration: 500,
          complete: () => {
            page.classList.remove("clipped")
          },
        }).finished
    )
  }
}
