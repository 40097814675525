<template>
  <div class="gui__top-actions gui__element gui__element--top-right">
    <!-- Phone -->
    <a
      class="phone mobile-hide"
      v-if="tour.phone"
      :href="`tel:${tour.phone}`"
      v-html="tour.phone"
    >
    </a>

    <!-- Booking -->
    <Button
      v-if="tour.booking"
      :link="tour.booking"
      :outbound="true"
      class="mobile-hide"
    >
      {{ $t.uiBookNow }}
    </Button>

    <!-- Language -->
    <div class="select-language" v-if="availableLocales.length > 1">
      <v-select
        :options="availableLocales"
        v-model="selected"
        class="select-wrapper"
        :reduce="(x) => x.key"
        :searchable="false"
        :clearable="false"
        :dropdown-should-open="dropdownShouldOpen"
        @input="selectLocale()"
        @click.native="clickSelect"
      >
        <template #open-indicator="{ attributes }"
          ><svg
            v-bind="attributes"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.06665 9.83795L12.0667 15.838L18.0667 9.83795"
              stroke="white"
              stroke-linecap="square"
            />
          </svg>
        </template>
        <template #option="option">
          <div class="flag">
            <img :src="`/assets/img/icons/flags/${option.key}.svg`" />
          </div>
          <span>{{ isTouchDevice() ? option.code : option.label }}</span>
        </template>
        <template #selected-option="option">
          <span>{{ isTouchDevice() ? option.code : option.label }}</span>
        </template>
      </v-select>
    </div>
    <transition name="show-modal">
      <div
        v-if="modal"
        class="select-language-modal"
        @click.prevent="modal = false"
      >
        <ul>
          <a
            class="select-language-modal__close"
            href="#!"
            @click.prevent="$emit('close')"
            tabindex="-1"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18"
                stroke="#045E51"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="#045E51"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
          <h3>Choose language</h3>
          <li
            v-for="option in availableLocales"
            :key="option.key"
            @click.prevent="selectLocale(option.key)"
            :class="`${option.key === selected ? 'active' : ''}`"
          >
            <div class="flag">
              <img :src="`/assets/img/icons/flags/${option.key}.svg`" />
            </div>
            <span>{{ option.label }}</span>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import Button from "@preview/Button.vue"
import virtualTourApi from "@/api"
import vSelect from "vue-select"
import { isTouchDevice } from "@/helpers"

export default {
  name: "TopActions",
  data() {
    return {
      tour: this.$store.state.currentTour,
      selected: this.$locale,
      availableLocales: [],
      modal: false,
    }
  },
  components: {
    Button,
    vSelect,
  },
  mounted() {
    virtualTourApi.getAvailableLocales().then((res) => {
      this.availableLocales = res.data
        .filter((s) => this.$store.state.currentTour.sites.includes(s.id))
        .map((s) => {
          return this.getLabel(s.key)
        })
      console.log(this.availableLocales)
    })
  },
  methods: {
    isTouchDevice: isTouchDevice,
    dropdownShouldOpen(VueSelect) {
      if (isTouchDevice()) {
        return false
      }
      return VueSelect.open
    },
    getLabel(key) {
      switch (key) {
        case "en_GB":
          return {
            label: "English (GB)",
            code: "GB",
            key: key,
          }
        case "de":
          return {
            label: "Deutsch (DE)",
            code: "DE",
            key: key,
          }
        case "nl_BE":
          return {
            label: "Nederlands (BE)",
            code: "BE",
            key: key,
          }
        case "nl_NL":
          return {
            label: "Nederlands (NL)",
            code: "NL",
            key: key,
          }
        case "fr_BE":
          return {
            label: "Français (BE)",
            code: "BE",
            key: key,
          }
        case "fr_FR":
          return {
            label: "Français (FR)",
            code: "FR",
            key: key,
          }
        case "fr_CH":
          return {
            label: "Français (CH)",
            code: "CH",
            key: key,
          }
        case "de_CH":
          return {
            label: "Deutsch (CH)",
            code: "CH",
            key: key,
          }
      }
    },
    clickSelect() {
      if (isTouchDevice()) {
        this.modal = true
      }
    },
    selectLocale(locale = null) {
      const selection = locale ? locale : this.selected

      this.selected = selection
      if (!window.embedded) {
        localStorage.setItem("locale", selection)
      }
      this.$locale = selection

      // Refetch data or go to craft page
      window.location.href = `/${selection}`
    },
  },
}
</script>

<style lang="scss">
:root {
  --vs-dropdown-option--active-bg: transparent;
}

.vs--open .vs__dropdown-toggle {
  @include small-up {
    background: white;

    span {
      white-space: nowrap;
      font-size: rs(16);
      font-weight: 500;
      color: var(--pine-color);
    }

    path {
      stroke: var(--pine-color);
    }
  }
}

.select-language {
  --vs-actions-padding: 0 0 0 0;
  width: 5rem;
  font-size: 1.4rem;
  line-height: 1.565rem;

  @include small-up {
    font-size: rs(17);
    line-height: rs(18);
    width: rs(180);
    border-radius: rs(16);
  }

  .vs__dropdown-toggle {
    width: 100%;

    @include small-up {
      &:hover {
        background: white;

        span {
          white-space: nowrap;
          font-size: rs(16);
          font-weight: 500;
          color: var(--pine-color);
        }

        path {
          stroke: var(--pine-color);
        }
      }

      font-size: rs(17);
      line-height: rs(18);
      width: rs(180);
      border-radius: rs(16);
    }
  }

  .select-wrapper {
    * {
      color: $color-white;
      text-transform: uppercase;
      font-weight: 400;

      @include small-up {
        text-transform: none;
      }
    }

    .vs__dropdown-toggle {
      min-height: 4rem;
      border: none;
      padding: 0;
      transition: all 0.1s linear;

      @include small-up {
        height: rs(44);
        min-height: rs(44);
        max-height: rs(44);
        padding: rs(14);
      }

      .vs__open-indicator {
        width: 2.4rem;
        height: 2.4rem;

        path {
          fill: transparent;
        }

        @include small-up {
          width: rs(24);
          height: rs(24);
        }
      }
    }

    .vs__dropdown-menu {
      box-shadow: none;
      border: none;
      width: 100%;
      overflow: hidden;
      margin-top: 1rem;
      background: transparent;
      padding: 0;

      min-height: unset;
      max-height: unset;
      height: unset;

      @include small-up {
        position: absolute;
        right: 0;
        left: unset;
        margin-top: rs(8);
        border-radius: rs(16);
        background: white;
      }

      .vs__dropdown-option {
        padding: 0.5rem;
        display: flex;
        align-items: center;

        span {
          color: var(--pine-color);
        }

        &--selected {
          background: var(--pine-color);

          span {
            color: white;
          }
        }

        @include small-up {
          padding: rs(12) rs(16);

          span {
            font-size: rs(16);
            font-weight: 500;
          }

          .flag {
            width: rs(24);
            height: rs(24);
            margin-right: rs(10);
            border-radius: 9999px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        @include small-up {
          &:hover {
            background: var(--pine-color);

            span {
              color: white;
            }
          }
        }
      }
    }

    .vs__selected {
      // opacity: 1;
      height: 100%;
      width: 100%;
      margin: 0;
      display: flex;
      align-items: center;

      @include small-up {
        opacity: 1 !important;

        span {
          white-space: nowrap;
        }
      }
    }
  }
}

.select-language-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 15;

  &.show-modal-enter-active,
  &.show-modal-leave-active {
    transition: all $basic-transition-200;

    ul {
      transition: all $basic-transition-200;
      transform: translate(-50%, -50%);
    }
  }

  &.show-modal-enter,
  &.show-modal-leave-to {
    opacity: 0;

    ul {
      opacity: 0;
      transform: translate(-50%, -60%);
    }
  }

  &__close {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    z-index: 1;
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    background: transparent;

    svg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all $bounce-transition-300;
    }

    &:hover svg {
      opacity: 0.7;
      transform: rotate(90deg) scale(1.2);
    }
  }

  h3 {
    padding: 1.6rem;
    color: var(--pine-color);
    font-size: 2rem;
    font-weight: 500;
  }

  ul {
    text-align: left;
    background: white;
    border-radius: 1.6rem;
    box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.25);
    min-height: 5rem;
    padding: 0;
    margin: 0;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    height: auto;
    width: 92%;

    li {
      padding: 1.2rem 1.6rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      transition: all 0.07s linear;
      cursor: pointer;
      color: var(--pine-color);

      span {
        font-size: 1.6rem;
        font-weight: 500;
      }

      .flag {
        width: 2.4rem;
        height: 2.4rem;
        margin-right: 1rem;
        border-radius: 9999px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &:hover,
      &:active,
      &.active {
        background-color: var(--pine-color);
        color: white;
      }
    }
  }
}
</style>
