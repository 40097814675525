import Vue from "vue";
import "./app.scss";
import App from "./App";
import store from "./store";
import router from "./router";
import VueGtm from "@gtm-support/vue2-gtm";
import DeviceDetector from "device-detector-js";
import VueMeta from "vue-meta";

Vue.use(VueMeta);
Vue.config.productionTip = false;

// Setup GTM
Vue.prototype.$gtmIdPresent = false;
if (process.env.VUE_APP_GTM_ID) {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID,
    loadScript: process.env.NODE_ENV === "production",
    vueRouter: router,
    enabled: true,
    debug: process.env.NODE_ENV === "development",
    ignoredViews: ["Virtual tour"],
  });
  Vue.prototype.$gtmIdPresent = true;
}

// GTM track event mixin
Vue.mixin({
  methods: {
    sendGtmEvent(
      eventName,
      additionalData,
      currentTour = null,
      currentPano = null
    ) {
      if (
        this.$gtmIdPresent &&
        this.$gtm.enabled() &&
        process.env.NODE_ENV === "production"
      ) {
        const deviceDetector = new DeviceDetector();
        const detected = deviceDetector.parse(navigator.userAgent);

        // Fire GTM event
        this.$gtm.trackEvent({
          event: eventName,
          ...additionalData,
          device_type: `${detected.device.type}`,
          device_os: `${detected.os.name}${detected.os.version}`,
          device_browser: `${detected.client.name}${detected.client.version}`,
          tour_title: currentTour,
          tour_pano: currentPano,
        });
        if (
          window.google_tag_manager &&
          window.google_tag_manager[process.env.VUE_APP_GTM_ID]
        ) {
          window.google_tag_manager[
            process.env.VUE_APP_GTM_ID
          ].dataLayer.reset();
        }
      }
    },
    sendGtmView(viewName, viewPath) {
      if (
        this.$gtmIdPresent &&
        this.$gtm.enabled() &&
        process.env.NODE_ENV === "production"
      ) {
        this.$gtm.trackView(viewName, viewPath);
        if (
          window.google_tag_manager &&
          window.google_tag_manager[process.env.VUE_APP_GTM_ID]
        ) {
          window.google_tag_manager[
            process.env.VUE_APP_GTM_ID
          ].dataLayer.reset();
        }
      }
    },
  },
});

new Vue({
  el: "#app",
  store,
  router,
  delimiters: ["${", "}"],
  components: { App },
});
