<template>
  <div class="gui__actions gui__element gui__element--bottom-right">
    <!-- Aerial view -->
    <a
      v-if="
        tour.aerial && tour.aerial.slug != this.$store.state.currentPano.slug
      "
      href="#!"
      @click.prevent="
        $loadPano(tour.aerial);
        $root.$emit('clicked-navigate');
      "
    >
      <img src="/assets/img/icons/icon-heli.svg" alt="Aerial icon" />
      <span class="label">
        {{ $t.uiAerialLabel }}
      </span>
    </a>

    <!-- Park map -->
    <a v-if="tour.map" href="#!" @click.prevent="">
      <img src="/assets/img/icons/icon-map.svg" alt="Map icon" />
      <span class="label">
        {{ $t.uiMapLabel }}
      </span>
    </a>
  </div>
</template>

<script>
export default {
  name: "Actions",
  data() {
    return {
      tour: this.$store.state.currentTour,
    };
  },
};
</script>
