import Vue from "vue"
import VueRouter from "vue-router"
import * as views from "../views"
import { getSubdomain } from "../helpers"
import virtualTourApi from "@/api"

// Set init locale
if (!window.embedded && localStorage.getItem("locale")) {
  Vue.prototype.$locale = localStorage.getItem("locale")
} else {
  Vue.prototype.$locale = process.env.VUE_APP_FALLBACK_LOCALE
}
if (!window.embedded) {
  localStorage.setItem("locale", Vue.prototype.$locale)
}
window.locale = Vue.prototype.$locale

Vue.use(VueRouter)

const routes = [
  {
    path: "/not-found",
    name: "Page not found",
    component: views.PageNotFound,
  },
  {
    path: "/",
    name: "Home",
    component: views.Home,
  },
  {
    path: "/:locale/c/:category+/p/:panorama?",
    name: "Virtual tour",
    component: views.Krpano,
  },
  {
    path: "/:locale/p/:panorama?",
    name: "Virtual tour",
    component: views.Krpano,
  },
  {
    path: "/:locale/",
    name: "Virtual tour",
    component: views.Krpano,
  },
  {
    path: "*",
    name: "Page not found",
    component: views.PageNotFound,
  },
]

const router = new VueRouter({
  mode: "history",
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (to.path === "/" || to.params.locale) {
    await checkSubdomain(to, from, next)
  }
  if (to.params.locale) {
    await checkLanguage(to, from, next)
  }
  next()
})

async function checkLanguage(to, from, next) {
  let availableLanguages = []
  await virtualTourApi.getAvailableLocales().then((res) => {
    availableLanguages = res.data.map((l) => l.key)
    if (!availableLanguages.includes(to.params.locale)) {
      next("/not-found")
    }
    next()
  })
}

async function checkSubdomain(to, from, next) {
  const subdomain = getSubdomain(process.env.VUE_APP_BASE_URL)
  if (subdomain) {
    if (!to.params.panorama) {
      // Get first pano of tour and navigate
      await virtualTourApi.getFirstPanoForTour(subdomain).then((res) => {
        const target = `/${Vue.prototype.$locale}/${
          res.data.category ? `c/${res.data.category}/` : ``
        }p/${res.data.pano}`
        if (to.fullPath !== target) {
          next(target)
        }
      })
    }
  }
  next()
}

window.popStateDetected = false
window.addEventListener("popstate", () => {
  window.popStateDetected = true
})

export default router
