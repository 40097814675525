<template>
    <div class="krpano__wrapper" @keydown.esc="modal = false">
      <div
        class="gui__shadow gui__shadow--top gui__element gui__element--top"
      ></div>
      <div
        class="gui__shadow gui__shadow--bottom gui__element gui__element--bottom"
      ></div>
      <transition-group name="fade-splash" mode="out-in">
        <SplashScreen
          v-if="showingSplash && loading"
          @close="showingSplash = false"
          key="splash"
        />
        <IntroScreen
          v-if="showIntro && !showingSplash && !loading"
          @close="showIntro = false"
          key="intro"
        />
      </transition-group>
  
      <Modal
        class="modal"
        :open="modal"
        :content="modalContent"
        @close="modal = false"
      />
      <SideMenu
        class="nav__side-menu"
        :open="sideMenu"
        @close="sideMenu = false"
      />
  
      <transition name="fade">
        <div class="gui" v-if="!modal && !sideMenu && !showIntro">
          <a
            v-if="!embedded"
            class="gui__logo gui__element gui__element--top-left"
            :href="`/${$locale}`"
            ><img :src="$l.logo" alt="Client logo"
          /></a>
  
          <Button
            v-if="webVRCapable && !webVRActive"
            class="gui__webvr gui__element gui__element--top-center"
            @click.native.prevent="startWebVR"
            >{{ $t.uiEnterVr }}</Button
          >
  
          <div
            v-if="$store.state.currentPano"
            class="gui__title gui__element gui__element--bottom-left"
          >
            <h1>{{ $store.state.currentPano.title }}</h1>
            <ReadMore v-if="description" :text="description" :max="500" />
            <Button v-if="cta" :link="cta" :outbound="true" class="book-button">
              {{ ctaLabel }}
            </Button>
            <BreadCrumb />
  
            <!-- Booking -->
            <Button
              v-if="$store.state.currentTour.booking && !cta"
              :link="$store.state.currentTour.booking"
              :outbound="true"
              class="desktop-hide book-button"
            >
              {{ $t.uiBookNow }}
            </Button>
          </div>
  
          <TopActions
            v-if="$store.state.currentTour"
            @open-side-menu="sideMenu = true"
          />
  
          <Actions v-if="$store.state.currentTour && !embedded" />
        </div>
      </transition>
      <div id="krpano"></div>
    </div>
  </template>
  
  <script>
  import Vue from "vue"
  import Modal from "@vtour/Modal.vue"
  import SplashScreen from "@vtour/SplashScreen.vue"
  import Hotspot from "@vtour/Hotspot.vue"
  import ReadMore from "@vtour/ReadMore.vue"
  import Button from "@vtour/Button.vue"
  import SideMenu from "@vtour/SideMenu.vue"
  import IntroScreen from "@vtour/IntroScreen.vue"
  import BreadCrumb from "@vtour/BreadCrumb.vue"
  import Actions from "@vtour/Actions.vue"
  import TopActions from "@vtour/TopActions.vue"
  import virtualTourApi from "@/api"
  import { addExitVrHotspot, addVrHotspots, removeVrHotspots } from "@/helpers/vr"
  import { isTouchDevice, getSubdomain } from "@/helpers"
  
  export default {
    name: "VirtualTour",
    components: {
      Hotspot,
      Modal,
      ReadMore,
      SplashScreen,
      IntroScreen,
      Button,
      SideMenu,
      BreadCrumb,
      Actions,
      TopActions,
    },
    computed: {
      description() {
        const parentCategory = this.$store.state.currentPano.categories.at(-1)
        if (
          this.$store.state.currentPano &&
          this.$store.state.currentPano.description
        ) {
          return this.$store.state.currentPano.description
        } else if (parentCategory && parentCategory.description) {
          return parentCategory.description
        }
        return null
      },
      cta() {
        const parentCategory = this.$store.state.currentPano.categories.at(-1)
        if (this.$store.state.currentPano && this.$store.state.currentPano.cta)
          return this.$store.state.currentPano.cta
        else if (parentCategory && parentCategory.cta) return parentCategory.cta
        return null
      },
      ctaLabel() {
        const parentCategory = this.$store.state.currentPano.categories.at(-1)
        if (this.$store.state.currentPano.ctaLabel)
          return this.$store.state.currentPano.ctaLabel
        else if (parentCategory.ctaLabel) return parentCategory.ctaLabel
        return this.$t.uiBookNow
      },
    },
    data() {
      return {
        showIntro: this.$t.introDisplay,
        showingSplash: true,
        loading: true,
        krpanoOptions: {
          id: "krpanoObject",
          target: "krpano",
          html5: "only+webgl",
          mobilescale: 1.0,
          wmode: "opaque",
          consolelog: process.env.NODE_ENV === "development",
          mwheel: true,
          onready: (krpano) => {
            window.krpanoObj = krpano.get("global")
            if (isTouchDevice()) {
              krpanoObj.control.mouse = "drag"
              krpanoObj.control.touch = "drag"
            } else {
              krpanoObj.control.mouse = "follow"
              krpanoObj.control.touch = "follow"
            }
          },
          initvars: {
            basePath: "/assets/js",
            partialPath: "/assets/js/partials",
            pluginPath: "/assets/js/plugins",
            assetPath: "/assets/js/assets",
            dev: process.env.NODE_ENV === "development",
            primaryColor: this.$l.primaryColor.replace("#", "0x"),
            secondaryColor: this.$l.secondaryColor.replace("#", "0x"),
            accentColor: this.$l.accentColor.replace("#", "0x"),
          },
        },
        sideMenu: false,
        modal: false,
        modalContent: {},
        webVRCapable: false,
        webVRActive: false,
        embedded: this.$route.query.embed === "true",
      }
    },
    mounted() {
      Vue.prototype.$loadPano = this.loadPano
      const subdomain = getSubdomain(process.env.VUE_APP_BASE_URL)
      if (subdomain) {
        this.fetchTour(subdomain)
      } else {
        window.location.href = process.env.VUE_APP_BASE_URL
      }
    },
    beforeRouteUpdate(to, from, next) {
      const isBackButton = window.popStateDetected
      window.popStateDetected = false
      const prevPano = this.$store.state.currentTour.panos.find(
        (p) => p.slug == to.params.panorama
      )
  
      if (isBackButton) {
        if (prevPano && prevPano !== this.$store.state.currentPano) {
          this.loadPano(prevPano, true)
        } else {
          history.back()
        }
      }
      next()
    },
    methods: {
      async fetchTour(slug) {
        virtualTourApi
          .getPanosForTour(slug)
          .then((res) => {
            this.$store.state.currentTour = res.data
            this.$store.state.currentCategories = res.data.categories
            this.setPano()
            this.initWindow()
            this.initTour()
          })
          .catch((err) => console.log(err))
      },
      setPano() {
        const panoSlug = this.$route.params.panorama
        const categorySlugs = this.$route.params.category
        const panos = this.$store.state.currentTour.panos
  
        if (panoSlug) {
          const pano = panos.find((p) => {
            if (p.categories && categorySlugs) {
              return (
                p.slug == panoSlug &&
                p.categories.map((c) => c.slug).join("/") === categorySlugs
              )
            } else {
              return p.slug == panoSlug
            }
          })
  
          if (pano) {
            this.$store.state.currentPano = pano
          } else {
            location.href = "/not-found"
          }
        } else {
          location.href = "/not-found"
        }
      },
      initWindow() {
        // Init loader
        window.startLoading = () => {
          this.loading = true
        }
        window.stopLoading = () => {
          this.loading = false
          this.showingSplash = false
        }
  
        // Back button
        window.popStateDetected = false
        window.addEventListener("popstate", () => {
          window.popStateDetected = true
        })
  
        // Web VR init
        window.setVrReadyStatus = (vrStatus) => {
          this.webVRCapable = vrStatus
        }
  
        // Init hotspots
        window.loadHotspot = async (spot, pano) => {
          if (
            this.$store.state.currentPano &&
            this.$store.state.currentPano.hotspots
          ) {
            let hotspot = this.$store.state.currentPano.hotspots.find(
              (h) => h.name === spot.name
            )
            if (hotspot) {
              if (hotspot.linkedScene || hotspot.linkedPano) {
                const sceneName = hotspot.linkedPano
                  ? hotspot.linkedPano.name
                    ? hotspot.linkedPano.name
                    : hotspot.linkedPano.panoName
                  : hotspot.linkedScene
                let linkedPano = this.$store.state.currentTour.panos.find(
                  (p) => p.name.toLowerCase() === sceneName.toLowerCase()
                )
                this.loadHotspotStyle(spot, hotspot, linkedPano)
              } else {
                this.loadHotspotStyle(spot, hotspot)
              }
            }
          }
        }
  
        // Handle VR hotspot click
        window.handleVrHotspotClick = (type, title, slug, name) => {
          switch (type) {
            case "navigation":
              this.loadPano(
                this.$store.state.currentTour.panos.find((p) => p.slug === slug)
              )
              break
            case "exit_vr":
              this.exitWebVR()
              break
            default:
              break
          }
        }
      },
      initTour() {
        // Show intro on first pano
        if (
          this.$store.state.currentTour.aerial &&
          this.$store.state.currentTour.aerial.id !==
            this.$store.state.currentPano.id
        ) {
          this.showIntro = false
        }
        if (
          !this.$store.state.currentTour.aerial &&
          this.$store.state.currentTour.panos[0] !== this.$store.state.currentPano
        ) {
          this.showIntro = false
        }
  
        const tour = this.$store.state.currentTour
        const pano = this.$store.state.currentPano
  
        if (tour && pano) {
          const xml = `/assets/vtours/${tour.id}/tour.xml?h=${+new Date()}`
          const { embedpano } = window
  
          embedpano({
            xml: xml,
            ...this.krpanoOptions,
          })
  
          this.loadPano(pano)
        }
      },
      loadPano(pano, retrace = false) {
        if (!pano) return
  
        // Load scene
        window.krpanoObj.call(
          `loadscene('${pano.name}', null, MERGE, BLEND(0.5));`
        )
  
        // Update state
        this.$store.state.currentPano = pano
  
        // Send view to GTM
        this.sendGtmView(
          `${pano.title} - Virtual tour`,
          `/${this.$locale}/${pano.slug}`
        )
  
        // Update router history
        if (this.$route.params.panorama !== pano.slug && !retrace) {
          if (pano.categories.length > 0) {
            this.$router.push(
              `/${this.$locale}/c/${pano.categories
                .map((c) => c.slug)
                .join("/")}/p/${pano.slug}`
            )
          } else {
            this.$router.push(`/${this.$locale}/p/${pano.slug}`)
          }
        }
  
        // Check if VR is active
        if (this.webVRActive) {
          addExitVrHotspot(this.$t.uiExitVr)
        }
      },
      async loadHotspotStyle(spot, hotspot, panorama) {
        if (panorama) {
          hotspot.linkedPano = panorama
        }
  
        // Set basic config
        spot.type = "text"
        spot.renderer = "css3d"
        spot.zoom = false
        spot.distorted = false
  
        if (spot.sprite) {
          // Set the id of the moving hotspot
          spot.sprite.id = `hotspot__locator--${hotspot.id}`
          // Set inner html for hotspot sprite
          const wrapper = document.createElement("div")
          wrapper.setAttribute("class", "hotspot__wrapper")
          let template = `
            <div id="hotspot-${hotspot.id}"></div>
            <div class="hotspot__outer"></div>
          `
          wrapper.innerHTML = template
  
          spot.sprite.appendChild(wrapper)
          // Init hotspot component onto template
          const HotspotCtor = Vue.extend(Hotspot)
          const hotspotElement = new HotspotCtor({
            propsData: {
              webVRActive: this.webVRActive,
              hotspot: hotspot,
            },
            parent: this,
          }).$mount(`#hotspot-${hotspot.id}`)
          // Set click action
          spot.onclick = () => {
            if (isTouchDevice()) {
              document.getElementById(hotspot.id).click()
            }
          }
          // Listen to hotspot
          hotspotElement.$on("navigate", (panorama) => {
            this.loadPano(panorama)
          })
          hotspotElement.$on("info", (content) => {
            this.modalContent = content
            this.modal = true
          })
        }
      },
      startWebVR() {
        addVrHotspots({
          continue: "Continue",
          exit: this.$t.uiExitVr,
        })
        this.webVRActive = true
        krpanoObj.call("webvr_onentervr();")
        krpanoObj.call("webvr.enterVR();")
        this.sendGtmEvent("webvr-open", {
          webvr_pano: this.$store.state.currentPano.title,
        })
      },
      exitWebVR() {
        removeVrHotspots()
        this.webVRActive = false
        krpanoObj.call("webvr.exitVR();")
        krpanoObj.call("webvr_onexitvr();")
        krpanoObj.call("lookto(0, 0)")
      },
    },
  }
  </script>
  
  <style lang="scss">
  @import "@styling/gui.scss";
  
  .krpano__wrapper {
    background: var(--accent-color);
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
  
    div {
      padding: 0;
      margin: 0;
    }
  
    // CSS Krpano hack
    & #krpanoObject {
      & > div {
        & > div:last-child {
          & > div {
            // #hotspot__wrapper
            width: 0 !important;
            height: 0 !important;
            background-color: transparent !important;
            border: 1px solid transparent;
  
            &:hover,
            &.locator__touched {
              z-index: 2005 !important;
            }
  
            & > div {
              & > div {
                overflow: visible !important;
  
                & > div {
                  margin: 0 !important;
                }
              }
            }
          }
        }
      }
    }
  }
  
  #krpano {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background-color: transparent;
  }
  
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity $basic-transition-200 0.1s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  
  .fade-splash-enter-active,
  .fade-splash-leave-active {
    transition: opacity $basic-transition-1000;
  }
  .fade-splash-enter,
  .fade-splash-leave-to {
    opacity: 0;
  }
  </style>
  