<template>
  <div class="krpano__wrapper">
    <TransitionElement icon="drop" />
    <div
      class="gui__shadow gui__shadow--top gui__element gui__element--top"
    ></div>
    <div
      class="gui__shadow gui__shadow--bottom gui__element gui__element--bottom"
    ></div>
    <transition-group name="fade-splash" mode="out-in">
      <SplashScreen
        v-if="showingSplash && loading"
        @close="showingSplash = false"
        key="splash"
      />
      <IntroScreen
        v-if="showIntro && !showingSplash && !loading"
        @close="showIntro = false"
        key="intro"
      />
    </transition-group>

    <transition name="fade">
      <div class="gui" v-if="!showIntro">
        <!-- <Loader v-if="$store.state.animating" /> -->

        <div class="gui__preview-banner gui__element gui__element--top">
          <p>Preview tour - Full experience coming soon!</p>
        </div>

        <a
          v-if="!embedded"
          class="gui__logo gui__element gui__element--top-left"
          :href="`/${$locale}`"
        >
          <img v-if="!isTouchDevice()" :src="$l.logo" alt="Client logo" />
          <img v-else src="/assets/img/logo-symbol.svg" alt="Client logo" />
        </a>

        <div
          v-if="$store.state.currentTour"
          class="gui__badge gui__element gui__element--top-center"
        >
          <img :src="$store.state.currentTour.badge" alt="Client logo" />
        </div>

        <Controls />

        <div
          v-if="$store.state.currentPano"
          class="gui__title gui__element gui__element--bottom-left"
        >
          <h2 v-if="currentCategory">{{ currentCategory }}</h2>
          <h1>{{ $store.state.currentPano.title }}</h1>
          <ReadMore v-if="description" :text="description" :max="500" />

          <!-- Booking -->
          <Button
            v-if="$store.state.currentTour.booking"
            :link="$store.state.currentTour.booking"
            :outbound="true"
            class="desktop-hide book-button"
          >
            {{ $t.uiBookNow }}
          </Button>
        </div>

        <div
          v-if="$store.state.currentPano"
          class="gui__subtitle gui__element gui__element--bottom-right"
        >
          <h3>{{ panoIndex + 1 }}/{{ panoCount }}</h3>
          <p>Top {{ panoCount }} Highlights</p>
        </div>

        <TopActions v-if="$store.state.currentTour" />
      </div>
    </transition>
    <div id="krpano"></div>
  </div>
</template>

<script>
import Vue from "vue"
import SplashScreen from "@preview/SplashScreen.vue"
import IntroScreen from "@preview/IntroScreen.vue"
import TopActions from "@preview/TopActions.vue"
import Controls from "@preview/Controls.vue"
import Button from "@preview/Button.vue"
import ReadMore from "@preview/ReadMore.vue"
// import Loader from "@preview/Loader.vue"
import TransitionElement from "@preview/TransitionElement.vue"
import virtualTourApi from "@/api"
import { Transitions } from "@/transitions"
import { isTouchDevice, getSubdomain } from "@/helpers"

export default {
  name: "Preview",
  components: {
    SplashScreen,
    IntroScreen,
    Controls,
    Button,
    ReadMore,
    TopActions,
    // Loader,
    TransitionElement,
  },
  computed: {
    panoCount() {
      return this.$store.state.currentTour.panos.length
    },
    panoIndex() {
      return this.$store.state.currentTour.panos.indexOf(
        this.$store.state.currentPano
      )
    },
    currentCategory() {
      if (
        this.$store.state.currentPano.categories &&
        this.$store.state.currentPano.categories.length > 0
      ) {
        const parentCategory = this.$store.state.currentPano.categories.at(-1)
        return parentCategory.title
      }
      return null
    },
    description() {
      const parentCategory = this.$store.state.currentPano.categories.at(-1)
      if (
        this.$store.state.currentPano &&
        this.$store.state.currentPano.description
      ) {
        return this.$store.state.currentPano.description
      } else if (parentCategory && parentCategory.description) {
        return parentCategory.description
      }
      return null
    },
    cta() {
      const parentCategory = this.$store.state.currentPano.categories.at(-1)
      if (this.$store.state.currentPano && this.$store.state.currentPano.cta)
        return this.$store.state.currentPano.cta
      else if (parentCategory && parentCategory.cta) return parentCategory.cta
      return null
    },
    ctaLabel() {
      const parentCategory = this.$store.state.currentPano.categories.at(-1)
      if (this.$store.state.currentPano.ctaLabel)
        return this.$store.state.currentPano.ctaLabel
      else if (parentCategory.ctaLabel) return parentCategory.ctaLabel
      return this.$t.uiBookNow
    },
  },
  data() {
    return {
      showIntro: this.$t.introDisplay,
      showingSplash: true,
      loading: true,
      krpanoOptions: {
        id: "krpanoObject",
        target: "krpano",
        html5: "only+webgl",
        mobilescale: 1.0,
        wmode: "opaque",
        consolelog: process.env.NODE_ENV === "development",
        mwheel: true,
        onready: (krpano) => {
          window.krpanoObj = krpano.get("global")
          if (isTouchDevice()) {
            krpanoObj.control.mouse = "drag"
            krpanoObj.control.touch = "drag"
          } else {
            krpanoObj.control.mouse = "follow"
            krpanoObj.control.touch = "follow"
          }
        },
        initvars: {
          basePath: "/assets/js",
          partialPath: "/assets/js/partials",
          pluginPath: "/assets/js/plugins",
          assetPath: "/assets/js/assets",
        },
      },
      embedded: this.$route.query.embed === "true",
    }
  },
  mounted() {
    window.showingSplash = this.showingSplash
    Vue.prototype.$loadPano = this.loadPano
    const subdomain = getSubdomain(process.env.VUE_APP_BASE_URL)
    if (subdomain) {
      this.fetchTour(subdomain)
    } else {
      window.location.href = process.env.VUE_APP_BASE_URL
    }
  },
  beforeRouteUpdate(to, from, next) {
    const isBackButton = window.popStateDetected
    window.popStateDetected = false
    const prevPano = this.$store.state.currentTour.panos.find(
      (p) => p.slug == to.params.panorama
    )

    if (isBackButton) {
      if (prevPano && prevPano !== this.$store.state.currentPano) {
        this.loadPano(prevPano, true)
      } else {
        history.back()
      }
    }
    next()
  },
  methods: {
    isTouchDevice: isTouchDevice,
    async fetchTour(slug) {
      virtualTourApi
        .getPanosForTour(slug)
        .then((res) => {
          this.$store.state.currentTour = res.data
          this.$store.state.currentCategories = res.data.categories
          this.setPano()
          this.initWindow()
          this.initTour()
        })
        .catch((err) => console.log(err))
    },
    setPano() {
      const panoSlug = this.$route.params.panorama
      const categorySlugs = this.$route.params.category
      const panos = this.$store.state.currentTour.panos

      if (panoSlug) {
        const pano = panos.find((p) => {
          if (p.categories && categorySlugs) {
            return (
              p.slug == panoSlug &&
              p.categories.map((c) => c.slug).join("/") === categorySlugs
            )
          } else {
            return p.slug == panoSlug
          }
        })

        if (pano) {
          this.$store.state.currentPano = pano
        } else {
          location.href = "/not-found"
        }
      } else {
        location.href = "/not-found"
      }
    },
    initWindow() {
      // Init loader
      window.startLoading = () => {
        this.loading = true
      }
      window.stopLoading = () => {
        this.loading = false
        window.showingSplash = this.showingSplash = false
      }

      // Back button
      window.popStateDetected = false
      window.addEventListener("popstate", () => {
        window.popStateDetected = true
      })

      // Web VR init
      window.setVrReadyStatus = (vrStatus) => {}
    },
    initTour() {
      // Show intro on first pano
      if (
        this.$store.state.currentTour.aerial &&
        this.$store.state.currentTour.aerial.id !==
          this.$store.state.currentPano.id
      ) {
        this.showIntro = false
      }
      if (
        !this.$store.state.currentTour.aerial &&
        this.$store.state.currentTour.panos[0] !== this.$store.state.currentPano
      ) {
        this.showIntro = false
      }

      const tour = this.$store.state.currentTour
      const pano = this.$store.state.currentPano

      if (tour && pano) {
        const xml = `/assets/vtours/${tour.id}/tour.xml?h=${+new Date()}`
        const { embedpano } = window

        embedpano({
          xml: xml,
          ...this.krpanoOptions,
        })

        this.loadPano(pano, false, true)
      }
    },
    async loadPano(pano, retrace = false, transition = false) {
      if (!pano) return

      if (!this.$store.state.animating) {
        this.$store.state.animating = true

        const load = () => {
          // Load scene
          window.krpanoObj.call(
            `loadscene('${pano.name}', null, MERGE, BLEND(0.5));`
          )

          // Update state
          this.$store.state.currentPano = pano

          // Send view to GTM
          this.sendGtmView(
            `${pano.title} - Virtual tour`,
            `/${this.$locale}/${pano.slug}`
          )

          // Update router history
          if (this.$route.params.panorama !== pano.slug && !retrace) {
            if (pano.categories.length > 0) {
              this.$router.push(
                `/${this.$locale}/c/${pano.categories
                  .map((c) => c.slug)
                  .join("/")}/p/${pano.slug}`
              )
            } else {
              this.$router.push(`/${this.$locale}/p/${pano.slug}`)
            }
          }
        }

        if (transition) {
          await Transitions.dropTransition(load)
        } else {
          load()
        }

        setTimeout(() => {
          this.$store.state.animating = false
        }, 500)
      }
    },
  },
}
</script>

<style lang="scss">
@import "@styling/gui.scss";

.krpano__wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--grass-color);

  &.clipped {
    clip-path: url(#invert);
  }

  div {
    padding: 0;
    margin: 0;
  }

  // CSS Krpano hack
  & #krpanoObject {
    & > div {
      & > div:last-child {
        & > div {
          // #hotspot__wrapper
          width: 0 !important;
          height: 0 !important;
          background-color: transparent !important;
          border: 1px solid transparent;

          &:hover,
          &.locator__touched {
            z-index: 2005 !important;
          }

          & > div {
            & > div {
              overflow: visible !important;

              & > div {
                margin: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}

#krpano {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: transparent;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity $basic-transition-200 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-splash-enter-active,
.fade-splash-leave-active {
  transition: opacity $basic-transition-1000;
}
.fade-splash-enter,
.fade-splash-leave-to {
  opacity: 0;
}

.gui {
  &__element {
    &--top-right {
      top: calc(#{$ui-padding-mobile} + 3.6rem) !important;

      @include small-up {
        top: calc(#{$ui-padding} + #{rs(36)}) !important;
      }
    }

    &--top-left {
      top: calc(#{$ui-padding-mobile} + 3.6rem) !important;

      @include small-up {
        top: calc(#{$ui-padding} + #{rs(36)}) !important;
      }
    }

    &--top-center {
      top: calc(#{$ui-padding-mobile} + 3.6rem) !important;

      @include small-up {
        top: calc(#{$ui-padding} + #{rs(36)}) !important;
      }
    }
  }

  &__preview-banner {
    display: none;
    background-color: var(--honey-color);
    text-align: center;
    padding: 1rem !important;

    p {
      color: var(--sunset-color);
      font-size: 1.4rem;
      font-weight: 500;

      @include small-up {
        font-size: rs(14);
      }
    }
  }

  &__title {
    max-width: calc(100% - calc(2 * #{$ui-padding-mobile}));
    bottom: calc(4.8rem + 2.2rem);

    @include small-up {
      max-width: 80%;
      bottom: $ui-padding;
    }

    h2 {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.345rem;
      letter-spacing: 0.12rem;
      margin-bottom: 0.4rem;

      @include small-up {
        font-size: rs(16);
        line-height: rs(18);
        letter-spacing: rs(1.6);
        margin-bottom: rs(8);
      }
    }

    h1 {
      font-size: 3.2rem;
      line-height: 3.4rem;
      font-weight: 500;

      @include small-up {
        font-size: rs(64);
        line-height: rs(68);
      }
    }
  }

  &__subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: var(--grass-color);
    padding: $ui-padding-mobile !important;

    @include small-up {
      justify-content: unset;
      align-items: unset;
      flex-direction: column;
      width: auto;
      bottom: $ui-padding;
      right: $ui-padding;
      background-color: transparent;
    }

    * {
      text-align: left;
      color: var(--pine-color);

      @include small-up {
        text-align: right;
        color: white;
      }
    }

    h3 {
      font-size: 1.4rem;
      line-height: 1.565rem;
      margin-bottom: 00;
      font-weight: 500;

      @include small-up {
        font-size: rs(32);
        margin-bottom: rs(16);
      }
    }

    p {
      font-size: 1.4rem;
      line-height: 1.565rem;
      font-weight: 400;

      @include small-up {
        font-size: rs(18);
        line-height: rs(20);
      }
    }
  }

  &__logo {
    width: 4rem;
    min-height: 3.4rem;
    // top: calc(2.8rem + 3.6rem) !important;
    top: calc(2.8rem) !important;

    @include small-up {
      // top: rs(76) !important;
      top: rs(56) !important;
      width: rs(217);
      height: rs(37);
    }
  }

  &__top-actions {
    // top: calc(2.5rem + 3.6rem) !important;
    top: calc(2.5rem) !important;

    @include small-up {
      // top: rs(70) !important;
      top: rs(50) !important;
    }
  }

  &__badge {
    top: 1.5rem !important;

    @include small-up {
      // top: rs(59) !important;
      top: rs(37) !important;
    }

    img {
      width: 8.8rem;
      height: 6rem;

      @include small-up {
        width: rs(108);
        height: rs(74);
      }
    }
  }
}
</style>
