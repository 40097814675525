import axios from "axios";

axios.defaults.baseURL = `${window.location.origin}${process.env.VUE_APP_API_URL}`;
axios.defaults.params = {
  locale: window.embedded ? window.locale : localStorage.getItem("locale"),
};

const virtualTourApi = {
  getAvailableLocales: async () => {
    return await axios.get(`/locales`);
  },
  getFirstPanoForTour: async (slug) => {
    return await axios.get(`/first?slug=${slug}`);
  },
  getAllTours: async () => {
    return await axios.get(`/`);
  },
  getPanosForTour: async (slug) => {
    return await axios.get(`?slug=${slug}`);
  },
};

export default virtualTourApi;
