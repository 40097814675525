<template>
  <a
    :href="link"
    class="button"
    :target="outbound ? '_blank' : ''"
    :rel="outbound ? 'noopener noreferrer' : ''"
  >
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: "Button",
  props: {
    link: { default: "#!" },
    outbound: { default: false },
  },
}
</script>

<style lang="scss" scoped>
.button {
  background: linear-gradient(
    to right,
    var(--pine-color-darker) 50%,
    var(--pine-color) 50%
  );
  background-size: 200% 110%;
  background-position: right bottom;
  border-radius: 9999px;
  box-sizing: border-box;
  color: var(--grass-color);
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  overflow: hidden;
  transition: all $basic-transition-300;
  text-align: right;
  max-width: 25rem;
  padding: 1.6rem 3.2rem;
  font-size: 1.7rem;
  font-weight: 500;

  &:hover {
    background-position: left bottom;
  }

  @include small-up {
    max-width: rs(250);
    font-size: rs(17);
    line-height: rs(19);
    padding: rs(16) rs(32);
  }
}
</style>
