<template>
  <router-view />
</template>

<script>
import Vue from "vue"

export default {
  name: "App",
  props: ["translations", "layout"],
  created() {
    Vue.prototype.$t = this.translations
    Vue.prototype.$l = this.layout
    this.setupLayout()
  },
  methods: {
    adjustColor(color, amount) {
      return (
        "#" +
        color
          .replace(/^#/, "")
          .replace(/../g, (color) =>
            (
              "0" +
              Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(
                16
              )
            ).substr(-2)
          )
      )
    },
    setupLayout() {
      document.documentElement.style.setProperty(
        "--primary-color",
        this.$l.primaryColor
      )
      document.documentElement.style.setProperty(
        "--secondary-color",
        this.$l.secondaryColor
      )
      document.documentElement.style.setProperty(
        "--secondary-color-lighter",
        this.adjustColor(this.$l.secondaryColor, 20)
      )
      document.documentElement.style.setProperty(
        "--secondary-color-darker",
        this.adjustColor(this.$l.secondaryColor, -20)
      )
      document.documentElement.style.setProperty(
        "--accent-color",
        this.$l.accentColor
      )
      document.documentElement.style.setProperty("--honey-color", "#FFE2A1")
      document.documentElement.style.setProperty("--sunset-color", "#700606")
      document.documentElement.style.setProperty("--grass-color", "#daffd1")
      document.documentElement.style.setProperty("--pine-color", "#045e51")
      document.documentElement.style.setProperty(
        "--pine-color-darker",
        this.adjustColor("#045e51", -20)
      )
    },
  },
}
</script>
