<template>
  <div class="gui__top-actions gui__element gui__element--top-right">
    <!-- Phone -->
    <a
      class="phone mobile-hide"
      v-if="tour.phone"
      :href="`tel:${tour.phone}`"
      v-html="tour.phone"
    >
    </a>

    <!-- Booking -->
    <Button v-if="tour.booking" :link="tour.booking" :outbound="true" class="mobile-hide">
      {{ $t.uiBookNow }}
    </Button>

    <!-- Menu -->
    <a
      href="#!"
      @click.prevent="$emit('open-side-menu')"
      class="side-menu__button"
    >
      <div class="side-menu__button-icon">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
      <span class="label">
        {{ $t.uiMenuLabel }}
      </span>
    </a>
  </div>
</template>

<script>
import Button from "@vtour/Button.vue";

export default {
  name: "TopActions",
  data() {
    return {
      tour: this.$store.state.currentTour,
    };
  },
  components: {
    Button,
  },
};
</script>
