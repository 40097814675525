<template>
  <div class="controls">
    <div class="control control--prev" @click="handlePrev()">
      <svg
        width="41"
        height="41"
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.375 30.75L25.625 20.5L15.375 10.25"
          stroke="#045E51"
          stroke-width="3"
          stroke-linecap="square"
        />
      </svg>
    </div>
    <div class="control control--next" @click="handleNext()">
      <svg
        width="41"
        height="41"
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.375 30.75L25.625 20.5L15.375 10.25"
          stroke="#045E51"
          stroke-width="3"
          stroke-linecap="square"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { isTouchDevice } from "@/helpers"

export default {
  computed: {
    panoCount() {
      return this.$store.state.currentTour.panos.length
    },
    panoIndex() {
      return this.$store.state.currentTour.panos.indexOf(
        this.$store.state.currentPano
      )
    },
    prevPano() {
      const prevPano = this.$store.state.currentTour.panos[this.panoIndex - 1]
      return prevPano
    },
    nextPano() {
      const nextPano = this.$store.state.currentTour.panos[this.panoIndex + 1]
      return nextPano
    },
  },
  methods: {
    isTouchDevice: isTouchDevice,
    handlePrev() {
      this.$loadPano(
        this.prevPano
          ? this.prevPano
          : this.$store.state.currentTour.panos[this.panoCount - 1]
      )
    },
    handleNext() {
      this.$loadPano(
        this.nextPano ? this.nextPano : this.$store.state.currentTour.panos[0]
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.controls {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + 2px);
  height: 5.7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: none !important;

  @include small-up {
    height: rs(76);
  }

  .control {
    position: relative;
    height: 100%;
    display: grid;
    place-items: center;
    pointer-events: all;
    background: var(--grass-color);
    cursor: pointer;
    transition: width ease 0.15s, background-color ease 0.15s;
    width: 5.7rem;

    @include small-up {
      width: rs(76);
    }

    svg {
      object-fit: contain;
      position: absolute;
      width: 3.1rem;
      height: 3.1rem;

      path {
        stroke: var(--pine-color);
        transition: stroke ease 0.15s;
      }

      @include small-up {
        width: rs(41);
        height: rs(41);
      }
    }

    &--prev {
      border-radius: 0 9999px 9999px 0;

      svg {
        transform: rotate(180deg);
        right: 1.8rem;

        @include small-up {
          right: rs(24);
        }
      }
    }

    &--next {
      border-radius: 9999px 0 0 9999px;

      svg {
        left: 1.8rem;

        @include small-up {
          left: rs(24);
        }
      }
    }

    @include small-up {
      &:hover {
        width: rs(100);
        background: var(--pine-color);

        path {
          stroke: var(--grass-color);
        }
      }
    }
  }
}
</style>
